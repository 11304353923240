import styled from 'styled-components';

export const ButtonGroupColumn = styled.div`
  margin-right: 20px;

  &.flex-center-mobile {
    @media all and (max-width: 800px) {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-right: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.full-width {
    width: 100%;
  }
  &.col-2 {
    width: calc((100% / 2) - 20px);
    min-width: max-content;

    @media all and (max-width: ${(props) => props.theme.mediaSizes.medium}px) {
      width: 100%;
    }
  }

  &.col-3 {
    width: calc((100% / 4) - 20px);
    min-width: 200px;

    @media all and (max-width: ${(props) => props.theme.mediaSizes.medium}px) {
      width: 100%;
    }
  }

  &.space-bottom {
    margin-bottom: 1.25rem;
  }

  &:last-child {
    margin-right: 0;
  }

  @media all and (max-width: ${(props) => props.theme.mediaSizes.medium}px) {
    margin-right: 0;
  }
`;
