import * as React from 'react';
import styled, { css } from 'styled-components';
import { ArrowIcon } from '../icons/Arrow';

interface ISelect {
  name?: string;
  labelAfter?: string;
  placeholder?: string;
  label?: string;
  value?: string | number;
  error?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  children?: any;
  className?: string;
}

export const Select: React.FC<ISelect> = React.memo((props) => {
  const selectRef = React.useRef(null);

  return (
    <StyledInputWrapper className="select-wrapper">
      {props.label && (
        <TextLabel className={props.className}>{props.label}</TextLabel>
      )}
      <StyledInputInsideWrapper>
        <ArrowIcon className="arrowdown" />
        <StyledInput
          className="input-boligtype"
          ref={selectRef}
          name={props.name}
          id={`select-${props.name}`}
          placeholder={props.label || props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          error={props.error || false}
        >
          {props.children}
        </StyledInput>
      </StyledInputInsideWrapper>
    </StyledInputWrapper>
  );
});

const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  height: 53px;

  label {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 53px;
    padding: 0 15px;
    line-height: 53px;
    background: ${(props: any) => props.theme.input.background};
  }
  .arrowdown {
    display: flex;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 10px;
    bottom: 0;
    margin: auto;
    height: max-content;
    svg {
      width: 20px;
      height: 20px;
    }
    path {
      stroke: #fff;
    }
  }
`;

const StyledInputInsideWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.select<{ error: boolean }>`
  display: block;
  padding: 15px;
  width: 100%;
  background: ${(props) => props.theme.input.background};
  font-size: ${(props) => props.theme.input.font.size};
  font-weight: 400;
  color: white;
  border: ${(props) =>
    props.error
      ? `solid 2px ${props.theme.input.error}`
      : 'solid 2px transparent'};
  outline: none;
  border-radius: 0;
  appearance: none;

  &::-webkit-input-placeholder {
    color: ${(props: any) => props.theme.input.placeholder};
  }
`;

const TextLabel = styled.div`
  margin-bottom: 10px;
  color: ${(props) => props.theme.input.label};
`;
