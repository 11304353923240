import * as React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../ui/icons/Close';

interface ITypeButton {
  children?: any;
  close?: (e: any) => void;
  value: string;
}

export const TypeButton: React.FC<ITypeButton> = (props) => {
  return (
    <Type>
      {props.value}
      {props.close && (
        <Close
          onClick={() => {
            if (typeof props.close === 'function') {
              props.close(props.value);
            }
          }}
        >
          <CloseIcon className="close-icon" />
        </Close>
      )}
    </Type>
  );
};

const Type = styled.div<any>`
  background-color: #e8c893;
  color: #000;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  margin: 5px 5px 0 0;
  position: relative;
`;

const Close = styled.div`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  .close-icon {
    width: inherit;
    height: inherit;

    svg {
      width: inherit;
      height: inherit;
      path {
        fill: #000;
      }
    }
  }
`;
